
<template>
  <div>
    <v-card class="mt-5" outlined style="border-left: 5px solid #1976D2;">
      <v-card-title class="d-flex flex-column align-start">
        <div 
          :class="['font-weight-medium blue-grey--text text--darken-4', card_title]" 
          style="word-break: break-word"
        >Formulario de avaliação</div>
        <div 
          :class="['font-weight-medium blue-grey--text text--darken-4', card_text]" 
          style="word-break: break-word"
        >{{item.name}}</div>
      </v-card-title>
      <v-card-text class="pb-1">
        <div 
          :class="['font-weight-medium blue-grey--text text--darken-4 pb-1', card_text]" 
          style="word-break: break-word"
        >Necessário anexos: <span class="text-body-2">{{item.is_international_course && item.kind == 'in_person' ? 'Sim' : 'Não'}}</span> </div>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn @click="$emit('itemId', item.id)" small color="primary">
          <v-icon left>
            mdi-pencil
          </v-icon>
          Preencher
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>

export default {
  name: 'DefaultCertificateCard',
  props: {
    item: {
      type: Object,
      default: null
    },
  },
  computed: {
    card_title(){
      return this.$vuetify.breakpoint.smAndUp ? 'text-h6' : 'text-subtitle-1'
    },
    card_text(){
      return this.$vuetify.breakpoint.smAndUp ? 'text-body-1' : 'text-body-2'
    },
  },
}
</script>

<style scoped>

</style>